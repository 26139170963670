import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  SelectChangeEvent,
  Box,
  TextField,
  Autocomplete,
  InputAdornment,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  LinearProgress,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloudUploadOutlined from "@mui/icons-material/CloudUploadOutlined";

import DragAndDrop, { ZipStatus } from "../components/uploader//DragAndDrop";
import JSZip, { JSZipMetadata } from "jszip";
import { useApi } from "../hooks/useApi";
import IconTooltip from "../components/uploader/IconTooltip";
import InfoSnackbar from "../components/uploader/InfoSnackbar";

import {
  DropDownValue,
  FormField,
  Solution,
  UploadChunk,
  UploadCompleteResponse,
  UploadParams,
} from "../types";
import {
  Apartment,
  CheckCircle,
  Extension,
  Folder,
  Person,
  UploadFile,
  ModeEdit,
} from "@mui/icons-material";

import { CDN_IMAGES } from "../util/images";
import { humanFileSize } from "../util/utils";
import { isTestingWithJest } from "../util/testing";

interface UploadViewProps {
  isOnline: boolean;
  getKID: () => string;
}

export const UploadView = (props: UploadViewProps) => {
  const { getAllOrganisations, getAllSolutions, upload } = useApi();
  const chunkSize = 2 * (1024 * 1024); // 2MB
  const [organisations, setOrganisations] = useState<DropDownValue[]>([]);
  const [solutionDisabled, setSolutionDisabled] = useState<boolean>(true);
  const [solutions, setSolutions] = useState<Solution[]>();
  const [filteredSolutions, setFilteredSolutions] = useState<
    { leanIx: string; label: string; value: string }[]
  >([]);
  const [processIntermediateName, setProcessIntermediateName] =
    useState<string>("");
  const [editDatesetNameDialog, setEditDatesetNameDialog] =
    useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [currentFile, setCurrentFile] = useState<File | undefined>(undefined);
  const [uploadingFile, setUploadingFile] = useState<File | undefined>(
    undefined,
  );
  const [zipStats, setZipStats] = useState<ZipStatus>({
    percentage: 50,
    fileName: "",
    inProgress: false,
  });
  const [uploadParams, setUploadParams] = useState<UploadParams>({
    chunkIndex: 0,
    startRange: 0,
    stopRange: 0,
    buffer: 1,
    processId: "",
  });

  const [formData, setFormData] = useState<Record<FormField, string>>({
    organisation: "",
    solution: "",
    mediadataType: "DroneImage",
    process: "",
    file: "",
  });

  const [formErrors, setFormErrors] = useState<Record<FormField, string>>({
    organisation: "",
    mediadataType: "",
    solution: "",
    process: "",
    file: "",
  });

  useEffect(() => {
    if (formData.organisation !== "") {
      const tmpFilteredSolutions = solutions
        ?.filter(
          (elem) =>
            elem?.relatedTenant &&
            elem.relatedTenant.id === formData.organisation,
        )
        .map((elem) => {
          return {
            leanIx: elem.leanIx,
            label: elem.name,
            value: elem.id || "",
          };
        });
      setFilteredSolutions(tmpFilteredSolutions || []);
    } else {
      setFilteredSolutions([]);
    }
  }, [formData.organisation, solutions]);

  useEffect(() => {
    if (!currentFile) setFormData((formData) => ({ ...formData, process: "" }));
  }, [currentFile]);

  useEffect(() => {
    Promise.all([getAllOrganisations(), getAllSolutions()])
      .then(([orgaResponse, solResponse]) => {
        if (orgaResponse !== null) {
          const tmpOrganisations = orgaResponse.map((elem) => {
            return {
              label: elem.organisation,
              value: elem.id as string,
            };
          });
          setOrganisations(tmpOrganisations);
        }

        if (solResponse !== null) {
          setSolutions(solResponse);
        }
      })
      .catch(() => {
        // TODO display error - reload page?
      });
  }, [setOrganisations, setSolutions]);

  useEffect(() => {
    if (formData.organisation) {
      setSolutionDisabled(false);
    } else {
      setSolutionDisabled(true);
    }
  }, [formData.organisation, formData.solution]);

  const handleOrganisationChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: string | DropDownValue | null,
  ) => {
    if (value === null || value === undefined) {
      setFormData({ ...formData, organisation: "", solution: "" });
      return;
    }

    setFormData({
      ...formData,
      organisation: (value as DropDownValue).value,
      solution: "",
    });
  };

  const handleSolutionInput = (event: SelectChangeEvent) => {
    if (!solutions) return;
    const selectedSolution = solutions?.find(
      (solution) => solution.id === event.target.value,
    );
    setFormData({
      ...formData,
      solution: selectedSolution?.id || "",
      mediadataType:
        selectedSolution?.mediadataTypes[0] || formData.mediadataType,
    });
  };

  // remove booleans and use ?? for hasErrors
  // "mediadata.uploader.error.file.noInput",
  const validateForm = () => {
    const newFormErrors: Record<FormField, string> = {
      organisation: "",
      solution: "",
      mediadataType: "",
      process: "",
      file: "",
    };

    if (!formData.organisation) {
      newFormErrors.organisation = "Organisation is required";
    }

    if (!formData.solution) {
      newFormErrors.solution = "Solution is required";
    }

    if (!formData.process) {
      newFormErrors.process = "Process name is required";
    }

    if (formData.process && !/^[a-zA-Z0-9_.-]{0,512}$/.test(formData.process)) {
      newFormErrors.process = "No special or more than 50 characters.";
    }

    if (currentFile === undefined) {
      newFormErrors.file = "You need to select a file.";
    }

    if (currentFile !== undefined && currentFile.type !== "application/zip") {
      newFormErrors.file = "The uploaded file needs to be zip file";
    }

    // check if uploaded zip file contains a dir
    if (currentFile !== undefined) {
      JSZip.loadAsync(currentFile).then(
        (zip) => {
          zip.forEach((_relativePath: string, zipEntry: { dir: boolean }) => {
            if (zipEntry.dir) {
              newFormErrors.file =
                "Found directory in zip file, can not upload";
            }
          });
        },
        (error) => {
          if (!isTestingWithJest()) {
            // eslint-disable-next-line no-console
            console.log(`zip error: ${error}`);
          }
        },
      );
    }

    setFormErrors(newFormErrors);

    // return true if one value in newFormErrors is !== ""
    return !(Object.keys(newFormErrors) as Array<FormField>).some(
      (key) => newFormErrors[key] !== "",
    );
  };

  const uploadCleanup = () => {
    setIsUploading(false);
    setUploadSuccess(true);

    const successSnackbarTime = setInterval(() => {
      setCurrentFile(undefined);
      setUploadParams({
        chunkIndex: 0,
        startRange: 0,
        stopRange: 0,
        buffer: 1,
        processId: "",
      });
      clearInterval(successSnackbarTime);
    }, 5000);
  };

  const uploadFile = async () => {
    // ignore if not online
    if (!props.isOnline) {
      return;
    }

    const totalSize = currentFile!.size;
    const numChunks = totalSize / chunkSize;
    // Buffer Byte -> bytes 0-x/y -> Start Counting at 0 -> Need to append the last byte at the last chunk
    let buffer = 1;
    let processId = "";
    let chunkIndex = 0;
    let startRange = 0;
    let stopRange = 0;

    // recover from internet connection loss
    if (props.isOnline && isUploading) {
      buffer = uploadParams.buffer;
      processId = uploadParams.processId;
      chunkIndex = uploadParams.chunkIndex;
      startRange = uploadParams.startRange;
      stopRange = uploadParams.stopRange;
    }

    setIsUploading(true);
    setUploadSuccess(false);
    setProgress(0);
    setUploadingFile(currentFile);
    setProcessIntermediateName(formData.process);

    for (let i = chunkIndex; i < numChunks; i += 1) {
      stopRange = startRange + chunkSize;

      if (stopRange >= totalSize) {
        stopRange = totalSize;
        buffer = 0;
      }

      const currentChunk = currentFile!.slice(
        startRange,
        stopRange - buffer + 1,
      ); // .slice() excludes last index

      const solution = filteredSolutions.find(
        (item) => item.value === formData.solution,
      );

      const chunk: UploadChunk = {
        leanIx: solution ? solution?.leanIx : "",
        platformTenantId: formData.organisation,
        creationPersonId: props.getKID(),
        processId: processId,
        processName: formData.process,
        mediadataType: formData.mediadataType,
        filename: currentFile!.name,
        totalSize: totalSize,
        startRange: startRange,
        stopRange: stopRange - buffer,
        chunk: currentChunk!,
      };

      const response = await upload(chunk);

      // connection lost while / before uploading
      if (response === null) {
        setUploadParams({
          chunkIndex: i,
          startRange: startRange,
          stopRange: stopRange,
          buffer: buffer,
          processId: processId,
        });
        break;
      }

      if (!response?.data?.detail) return;
      processId = response.data.detail.processId as string;
      startRange = stopRange;

      const _progress = Math.ceil((100 / totalSize) * startRange);
      setProgress(_progress);

      // last chunk response terminates upload status
      if (
        (response as UploadCompleteResponse).data?.detail?.extraction !==
        undefined
      ) {
        uploadCleanup();
      }
    }
  };

  useEffect(() => {
    if (props.isOnline && isUploading) {
      uploadFile();
    }
  }, [props.isOnline]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateForm()) {
      // scroll to bottom of page, so user sees the progressbar
      window.scrollTo(0, document.body.scrollHeight);
      uploadFile();
    }
  };

  const handleDrop = (event: React.DragEvent) => {
    setFormErrors((errors) => ({
      ...errors,
      file: "",
    }));
    event.stopPropagation();
    event.preventDefault();

    const entry = event.dataTransfer.items[0].webkitGetAsEntry();
    const fileName = event.nativeEvent.dataTransfer?.files[0].name;

    if (!entry) return;
    if (entry.isFile) {
      (entry as FileSystemFileEntry).file((file) => {
        if (file.type != "application/zip") {
          setFormErrors((errors) => ({
            ...errors,
            file: "Only zip file and folder with only images are allowed.",
          }));
          return;
        }
        setCurrentFile(file);
        setFormData({
          ...formData,
          process: file.name.split(".")[0] || "images",
        });
      });
    } else if (entry.isDirectory) {
      const reader = (entry as FileSystemDirectoryEntry).createReader();
      const files: File[] = [];

      reader.readEntries(function (entries) {
        const totalFiles = entries.length;
        let counter = 0;
        entries.forEach((dir) => {
          (dir as FileSystemFileEntry).file((file) => {
            files.push(file);
            counter++;
            if (totalFiles == counter) {
              prepareForZip(files, fileName);
            }
          });
        });
      });
    }
  };

  const prepareForZip = async (
    files: File[],
    zipFileName?: string | undefined,
  ) => {
    const zip = new JSZip();
    let folder = "";
    files.forEach((file) => {
      zip.file(file.name, file);
      folder = file.webkitRelativePath;
    });

    const folderParts = folder.split("/");
    folder = zipFileName || folderParts[0] || "images";

    // set progress
    setZipStats({
      inProgress: true,
      fileName: "",
      percentage: 0,
    });

    const zipBlob = await zip.generateAsync(
      { type: "blob" },
      ({ percent, currentFile: file }: JSZipMetadata) => {
        // update progress
        setTimeout(() => {
          // has to add timeout so main thread can update and loader can show correct percent
          setZipStats((stats) => ({
            ...stats,
            fileName: file,
            percentage: percent,
          }));
        }, 0);
      },
    );
    const zipFile = new File([zipBlob], `${folder}.zip`, {
      type: "application/zip",
    });

    setCurrentFile(zipFile);
    setFormData({ ...formData, process: folder });

    setZipStats((stats) => ({
      ...stats,
      inProgress: false,
    }));
  };

  const onFileUploadChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.target.files == null) return;

    setFormErrors((errors) => ({
      ...errors,
      file: "",
    }));

    // user can upload only one file
    if (event.target.files.length > 1) {
      // its folder
      prepareForZip(Object.values(event.target.files));
    } else {
      // its zip file
      setCurrentFile(event.target.files[0]);
      setFormData({
        ...formData,
        process: event.target.files[0].name.split(".")[0] || "images",
      });
    }
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        paddingY="3em"
        gap={4}
      >
        <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
          <Box width="100px">
            <img
              src={CDN_IMAGES.logos("media_data_logos/md_uploader.svg")}
              height="100%"
              width="auto"
              alt="mdp-viewer"
            />
          </Box>
          <Typography variant="h4" fontWeight="bold">
            Welcome to Media Data Uploader
          </Typography>
          {/* <Typography variant="subtitle1">
            Bring your Media Data to iPEN
          </Typography> */}
        </Box>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{ width: "500px" }}
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap={2}
          >
            <Box display="flex" alignItems="start" gap={1} width={1}>
              <TextField
                id="user-textfield"
                fullWidth
                placeholder="Uploader"
                aria-label="Uploader Textfield"
                value={props.getKID()}
                disabled={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person />
                    </InputAdornment>
                  ),
                }}
              />
              <Box width={44} />
            </Box>
            <Box display="flex" alignItems="start" gap={1} width={1}>
              <Autocomplete
                id="organisation"
                data-testid="organization-dropdown"
                fullWidth
                disabled={false}
                options={organisations}
                onChange={handleOrganisationChange}
                // renderInput={(params) => <TextField {...params} label="Movie" />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder="Organisation"
                    aria-label="Organisation Textfield"
                    helperText={formErrors.organisation}
                    error={formErrors.organisation != ""}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Apartment />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <IconTooltip text="Enter your Organisation to identify the uploaded file" />
            </Box>

            <Box display="flex" alignItems="start" gap={1} width={1}>
              <FormControl fullWidth>
                <Select
                  labelId="solution-dropdown"
                  aria-label="Solution Dropdown"
                  value={
                    formData?.organisation && formData.solution
                      ? formData.solution
                      : "undefined"
                  }
                  disabled={solutionDisabled}
                  onChange={handleSolutionInput}
                  color="secondary"
                  data-testid="solution-dropdown"
                  fullWidth
                  aria-controls="dropdown-select"
                  role="combobox"
                  startAdornment={
                    <InputAdornment position="start">
                      <Extension />
                    </InputAdornment>
                  }
                >
                  <MenuItem disabled value="undefined">
                    Select Solution
                  </MenuItem>
                  {filteredSolutions &&
                    filteredSolutions.map((value, i) => {
                      return (
                        <MenuItem key={i} value={value.value}>
                          {value.label} ({value.leanIx})
                        </MenuItem>
                      );
                    })}
                </Select>
                {formErrors.solution != "" && (
                  <FormHelperText error>{formErrors.solution}</FormHelperText>
                )}
              </FormControl>

              <IconTooltip text="Select Solution to identify the uploaded file" />
            </Box>

            <Box display="flex" alignItems="start" gap={1} width={1}>
              <TextField
                id="dataset-textfield"
                fullWidth
                placeholder="Dataset name"
                disabled
                aria-label="Dataset Textfield"
                value={formData.process}
                helperText={formErrors.process}
                error={formErrors.process != ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Folder />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setProcessIntermediateName(formData.process);
                          setEditDatesetNameDialog(true);
                        }}
                      >
                        <ModeEdit />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <IconTooltip text="Enter your Dataset Name to identify the uploaded file" />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              gap={1}
              width={1}
              flexDirection="column"
            >
              <DragAndDrop
                dragAndDropAriaLabel="Drag and Drop field to upload Media Data"
                selectedFile={currentFile}
                resetFile={() => {
                  setCurrentFile(undefined);
                }}
                zipStats={zipStats}
                onFileChange={onFileUploadChange}
                handleDrop={handleDrop}
                helperText={formErrors.file}
                hasError={formErrors.file != ""}
                isUploading={isUploading}
                processName={formData.process}
              />
            </Box>

            {uploadingFile && (
              <Box
                display="flex"
                width={1}
                alignItems="center"
                border={1}
                py={2}
                borderRadius={1}
                borderColor={isUploading ? "secondary.main" : "success.main"}
              >
                <UploadFile
                  color={isUploading ? "secondary" : "success"}
                  sx={{ p: 2 }}
                />
                <Box display="flex" flexDirection="column" width={1} gap="3px">
                  <Typography variant="body1">{uploadingFile?.name}</Typography>
                  <Typography variant="body2" color="gray" component="div">
                    {humanFileSize(uploadingFile?.size || 0)} •{" "}
                    {isUploading ? (
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        pl={1}
                        gap={1}
                      >
                        <CircularProgress
                          size={12}
                          color="secondary"
                          data-testid="upload-loading"
                        />{" "}
                        Loading
                      </Box>
                    ) : (
                      "Complete"
                    )}
                  </Typography>
                  <LinearProgress
                    sx={{ width: "50%", borderRadius: 2, opacity: 0.7 }}
                    variant="determinate"
                    color={isUploading ? "secondary" : "success"}
                    value={progress}
                    aria-label="Progressbar to display the upload progress"
                  />
                </Box>
              </Box>
            )}

            <Button
              type="submit"
              data-testid="submit"
              variant="contained"
              color="secondary"
              size="large"
              disabled={isUploading}
              startIcon={<CloudUploadOutlined />}
            >
              Upload
            </Button>
          </Box>
        </form>
      </Grid>
      <InfoSnackbar
        open={!props.isOnline}
        message={
          !props.isOnline && isUploading
            ? `Internet connection lost - Failed to upload chunk ${uploadParams.chunkIndex}`
            : "Internet connection lost"
        }
        severity="warning"
        hideDuration={0}
      />
      <Dialog open={uploadSuccess} maxWidth="sm">
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={300}
            gap={2}
            textAlign="center"
          >
            <CheckCircle sx={{ fontSize: 52 }} color="success" />
            <Typography>
              Your Dataset {processIntermediateName} was successfully uploaded
              to the iPEN Media Data Platform
            </Typography>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              onClick={() => {
                setUploadSuccess(false);
              }}
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={editDatesetNameDialog} maxWidth="sm">
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={500}
            gap={2}
            textAlign="center"
          >
            <Typography>
              <strong>Change Dataset Name</strong>
            </Typography>
            <TextField
              id="dataset-name"
              fullWidth
              placeholder="Dataset Name"
              aria-label="Dataset Name"
              value={processIntermediateName}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => {
                setProcessIntermediateName(event.currentTarget.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Folder />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box pt={1} display="flex" justifyContent="end" gap={1}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              onClick={() => {
                setFormData((formData) => ({
                  ...formData,
                  process: processIntermediateName,
                }));
                setEditDatesetNameDialog(false);
              }}
            >
              Save
            </Button>
            <Button
              type="submit"
              variant="text"
              color="secondary"
              onClick={() => {
                setProcessIntermediateName(formData.process);
                setEditDatesetNameDialog(false);
              }}
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
