import Button from "@mui/material/Button";
import React from "react";
import { useMsal } from "@azure/msal-react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  logoutButton: {
    color: theme.palette.common.black,
  },
}));

const LogoutButton = () => {
  const { instance } = useMsal();
  const { classes } = useStyles();

  const handleSignout = () => {
    instance.logoutRedirect();
  };

  return (
    <Button
      id="logout"
      onClick={handleSignout}
      className={classes.logoutButton}
    >
      Logout
    </Button>
  );
};

export default LogoutButton;
