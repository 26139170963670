import React, { useEffect } from "react";
import {
  ChangeEvent,
  DragEvent,
  FunctionComponent,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/material";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import { makeStyles } from "tss-react/mui";
import { Delete, UploadFile } from "@mui/icons-material";

const useStyles = makeStyles()((theme) => ({
  dragDropField: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1em 0",
    cursor: "pointer",
    borderRadius: "4px",
    fontWeight: "bold",
    height: "130px",
    boxSizing: "initial",
  },
  active: {
    border: "1px dashed green",
  },
  inactive: {
    border: "1px dashed var(--divider, rgba(0, 0, 0, 0.12))",
    borderColor: theme.palette.secondary.main,
    "&:hover": {
      borderColor: "var(--primary-selected, rgba(169, 169, 227, 0.8))",
      backgroundColor: "var(--primary-selected, rgba(169, 169, 227, 0.08))",
      "& .icon": {
        color: "var(--primary-selected, rgba(169, 169, 227, 1))",
      },
    },
  },
  chip: {
    marginTop: "2em",
    marginBottom: "2em",
  },
}));

export interface ZipStatus {
  percentage: number;
  fileName: string | null;
  inProgress: boolean;
}

interface DragAndDropProps {
  dragAndDropAriaLabel: string;
  selectedFile: File | undefined;
  resetFile: () => void;
  helperText: string;
  hasError: boolean;
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDrop: (event: React.DragEvent) => void;
  zipStats: ZipStatus;
  processName: string;
  isUploading: boolean;
}

const DragAndDrop: FunctionComponent<DragAndDropProps> = (props) => {
  const { classes } = useStyles();
  const [inDropZone, setInDropZone] = useState<boolean>(false);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const inputFolder = useRef<HTMLInputElement | null>(null);

  const stopEventPropagation = (
    e: React.MouseEvent<HTMLElement> | DragEvent,
  ) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const resetFileInput = (e?: React.MouseEvent<HTMLElement>) => {
    e && stopEventPropagation(e);
    if (inputFolder.current && inputFile.current) {
      inputFile.current.value = "";
      inputFolder.current.value = "";
      props.resetFile();
    }
  };

  const onUploadFolderClick = (e: React.MouseEvent<HTMLElement>) => {
    stopEventPropagation(e);
    if (inputFolder !== null && inputFolder.current !== null) {
      inputFolder.current.click();
    }
  };
  const onUploadClick = () => {
    if (inputFile !== null && inputFile.current !== null) {
      inputFile.current.click();
    }
  };

  const handleDragEnter = (e: DragEvent) => {
    stopEventPropagation(e);
    setInDropZone(true);
  };

  const handleDragLeave = (e: DragEvent) => {
    stopEventPropagation(e);
    setInDropZone(false);
  };

  const handleDragOver = (e: DragEvent) => {
    stopEventPropagation(e);
    setInDropZone(true);
  };

  useEffect(() => {
    if (props.isUploading) {
      resetFileInput();
    }
  }, [props.isUploading]);

  return (
    <Box width={1}>
      <Box
        width={1}
        role="button"
        tabIndex={0}
        className={`${classes.dragDropField} ${
          inDropZone || props.selectedFile ? classes.active : classes.inactive
        }`}
        draggable
        data-testid="dropzone"
        aria-label={props.dragAndDropAriaLabel}
        onClick={() => {
          onUploadClick();
        }}
        onKeyDown={() => {
          onUploadClick();
        }}
        onDrop={(e) => props.handleDrop(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDragEnter={(e) => handleDragEnter(e)}
        onDragLeave={(e) => handleDragLeave(e)}
      >
        {props.zipStats.inProgress ? (
          <Box display="flex" alignItems="center" gap={1}>
            <CircularProgressWithLabel
              value={props.zipStats.percentage}
              size={42}
              color="secondary"
            />
            <Typography variant="body2" color="gray">
              Compressing {props.zipStats.fileName || "..."}
            </Typography>
          </Box>
        ) : !props.zipStats.inProgress && props.selectedFile ? (
          <Box display="flex" alignItems="center" flexDirection="column">
            <Typography>
              Dataset name: <strong>{props.processName}</strong>
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" color="gray">
                {props.selectedFile.name}
              </Typography>
              <IconButton onClick={resetFileInput}>
                <Delete />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap={1}
            >
              <UploadFile fontSize="large" color="secondary" className="icon" />
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  color="secondary.main"
                  fontWeight="bold"
                  fontSize={18}
                >
                  Click To upload &nbsp;
                </Typography>
                <Typography variant="body2" color="gray">
                  or drag and drop
                </Typography>
              </Box>
              <Typography variant="body2" color="gray">
                .zip files or folder (JPEG)
              </Typography>
              <Button
                variant="text"
                color="secondary"
                onClick={onUploadFolderClick}
                size="small"
              >
                <Typography
                  variant="body2"
                  color="secondary.main"
                  fontWeight="bold"
                  textTransform="initial"
                >
                  Click here to upload folder
                </Typography>
              </Button>
            </Box>
          </>
        )}
      </Box>
      <input
        id="file"
        type="file"
        data-testid="file-input"
        ref={inputFile}
        accept=".zip"
        style={{ display: "none" }}
        onChange={props.onFileChange}
      />
      <input
        id="folder"
        type="file"
        data-testid="folder-input"
        // @ts-expect-error needs following property to work
        // eslint-disable-next-line react/no-unknown-property
        directory=""
        webkitdirectory=""
        // eslint-disable-next-line react/no-unknown-property
        mozdirectory=""
        ref={inputFolder}
        style={{ display: "none" }}
        onChange={props.onFileChange}
      />

      {props.hasError && (
        <FormHelperText data-testid="file-error-label" error={props.hasError}>
          {props.helperText}
        </FormHelperText>
      )}
    </Box>
  );
};
export default DragAndDrop;
