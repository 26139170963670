import dayjs from "dayjs";

export const displayDateFormat = "YYYY/MM/DD";
export const searchDateFormat = "YYYY-MM-DD";

export const string2Date = (dateString: string | undefined) => {
  if (!dateString) return "N/A";
  return dayjs(dateString).format(displayDateFormat);
};

export const string2FullDate = (
  dateString: string | undefined,
  separator: string = "-",
) => {
  if (!dateString) return "N/A";
  return dayjs(dateString).format(`YYYY${separator}MM${separator}DD HH:MM`);
};
