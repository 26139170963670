import React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";

const pca = new PublicClientApplication({
  auth: {
    clientId: `${window.REACT_APP_CLIENT_ID}`,
    authority: `${window.REACT_APP_AUTHORITY}`,
    redirectUri: `${window.REACT_APP_REDIRECT_URI}`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
});

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    pca.setActiveAccount(account);
  }
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  // as StrictMode will call useEffect multiple times
  // we want to avoid calling apis multiple time on the load
  // <React.StrictMode>
  <App msalInstance={pca} />,
  // </React.StrictMode>,
);
