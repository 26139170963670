import React from "react";
import axios from "axios";
import { isTestingWithJest } from "../../util/testing";

const getProfilePhoto = async (token: string) => {
  try {
    const response = await axios.request({
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
      url: "https://graph.microsoft.com/v1.0/me/photos('48x48')/$value",
    });

    const blobUrl = window.URL.createObjectURL(response.data);

    return <img src={blobUrl} alt="User Profile Photo" />;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      // Handle 404 error (no profile photo found)
      return null;
    } else {
      if (!isTestingWithJest())
        console.error("Error fetching user photo:", error);
      // Handle other errors as needed
      return null;
    }
  }
};

export default getProfilePhoto;
