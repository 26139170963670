import axios, { AxiosError } from "axios";
import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";

import {
  ApiPlatformTenantsResponse,
  PlatformTenant,
  ResponseForMethod,
  ResponseForUpload,
  Solution,
  UploadChunk,
  paths,
} from "../types";

export const useApi = () => {
  const { instance } = useMsal();

  const getBaseUrl = () => {
    return window.REACT_APP_MEDIA_DATA_URL;
  };

  const getApiUrl = () => {
    return `${getBaseUrl()}/api/v1`;
  };

  const getHealth = async () => {
    const url = `${getBaseUrl()}/api/health`;
    const data = await apiCall<paths["/api/health"]>(url, { absolute: true });
    return data;
  };

  const getOrganisations = async (limit?: number, offset?: number) => {
    let url = `/platformtenants`;
    if (limit || offset) {
      url = `${url}?limit=${limit}&offset=${offset}`;
    }
    const data = await apiCall<paths["/api/v1/platformtenants"]>(url);
    return data;
  };

  const getAllOrganisations = async () => {
    const limit = 1000;
    let offset = 0;
    let response: ApiPlatformTenantsResponse;
    const organisations: PlatformTenant[] = [];
    do {
      response = await getOrganisations(limit, offset);
      if (response && response.results) {
        response.results.map((organisation) => {
          organisations.push(organisation);
        });
      }
      offset += limit;
    } while (response && response.next);
    return organisations;
  };

  const getSolutions = async (limit?: number, offset?: number) => {
    let url = "/solutions";
    if (limit || offset) {
      url = `${url}?limit=${limit}&offset=${offset}`;
    }
    const data = await apiCall<paths["/api/v1/solutions"]>(url);
    return data;
  };

  const getAllSolutions = async () => {
    const limit = 1000;
    let offset = 0;
    const solutions: Solution[] = [];
    let response;

    do {
      response = await getSolutions(limit, offset);
      if (response && response.results) {
        response.results.map((solution) => {
          solutions.push(solution);
        });
      }

      offset += limit;
    } while (response && response.next);

    return solutions;
  };

  const upload = async (uploadData: UploadChunk) => {
    const headers = {
      "Content-Size": `${uploadData.totalSize}`,
      "Content-Range": `bytes ${uploadData.startRange}-${uploadData.stopRange}/${uploadData.totalSize}`,
    };

    const formData = new FormData();
    formData.append("leanIx", uploadData.leanIx);
    formData.append("platformTenantId", uploadData.platformTenantId);
    formData.append("creationPersonId", uploadData.creationPersonId);
    formData.append("processId", uploadData.processId);
    formData.append("mediadataType", uploadData.mediadataType);
    formData.append("processName", uploadData.processName);
    formData.append("filename", uploadData.filename);
    formData.append("file", uploadData.chunk);

    const url = "/upload";
    const data = await apiPost<paths["/api/v1/upload"]>(url, headers, formData);
    return data;
  };

  const getToken = async () => {
    let token;
    let tokenError;
    const tenantAppId = `${window.REACT_APP_TENANT_APP_ID}`;

    try {
      const response = await instance.acquireTokenSilent({
        scopes: [`api://${tenantAppId}/.default`],
      });

      token = response.accessToken;
    } catch (error) {
      tokenError = error;
    }

    // if (tokenError) {
    //   console.log("Error acquiring access token", tokenError);
    //   throw tokenError;
    // }
    return token;
  };

  const apiCall = useCallback(
    async <Path>(
      url: string,
      options = { absolute: false },
    ): Promise<ResponseForMethod<Path, "get">> => {
      try {
        const token = await getToken();
        const apiUrl = options?.absolute ? url : `${getApiUrl()}` + url;
        const apiResponse = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return apiResponse.data;
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        console.log("Unhandled error in apiCall:", axiosError);
        throw axiosError;
        // return {
        //   error:
        //     axiosError.response ||
        //     axiosError.request ||
        //     axiosError.message ||
        //     axiosError,
        // };
      }
    },
    [instance],
  );

  const apiPost = useCallback(
    async <Path>(
      url: string,
      headers?: { "Content-Size": string; "Content-Range": string },
      formData?: FormData,
      options = { absolute: false },
    ): Promise<ResponseForUpload<Path, "post">> => {
      try {
        const token = await getToken();
        const apiUrl = options?.absolute ? url : `${getApiUrl()}` + url;
        const apiResponse = await axios.post(apiUrl, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            ...headers,
          },
        });

        return apiResponse.data;
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        console.log("Unhandled error in apiCall:", axiosError);
        throw axiosError;
        // return {
        //   error:
        //     axiosError.response ||
        //     axiosError.request ||
        //     axiosError.message ||
        //     axiosError,
        // };
      }
    },
    [instance],
  );

  return {
    apiCall,
    getAllOrganisations,
    getAllSolutions,
    getApiUrl,
    getHealth,
    upload,
  };
};
