import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import Link from "@mui/material/Link";
import AppsIcon from "@mui/icons-material/Apps";
import { List, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { HeaderIconButton } from "@ipen-portal/portal-components";
import { fetchData } from "./api/IpenClients";

const useStyles = makeStyles()(() => ({
  appSwitcherIcon: {
    height: 22,
    width: 22,
    objectFit: "contain",
  },
}));

export const AppSwitcher: FC = () => {
  const { classes } = useStyles();
  const { instance } = useMsal();
  const [apps, setApps] = useState<
    { title: string; url: string; logo: string }[]
  >([]);

  useEffect(() => {
    let mounted = true;
    (async () => {
      const apiResponse = await fetchData({
        instance,
        mounted,
        apiEndpoint: "app-switcher/apps",
        tenantAppId: `${window.REACT_APP_TENANT_APP_ID}`,
      });
      setApps(apiResponse?.response || []);
    })();

    return () => {
      mounted = false;
    };
  }, []);

  if (!apps.length) {
    return null;
  }

  return (
    <HeaderIconButton title={"App-Switcher"} icon={<AppsIcon />}>
      <List dense>
        {apps.map((app) => (
          <MenuItem
            key={app.title}
            component={Link}
            href={app.url}
            area-label={app.title}
          >
            <ListItemIcon>
              <img
                className={classes.appSwitcherIcon}
                src={app.logo}
                alt={app.title}
              />
            </ListItemIcon>
            <Typography variant="subtitle1" noWrap>
              {app.title}
            </Typography>
          </MenuItem>
        ))}
      </List>
    </HeaderIconButton>
  );
};
