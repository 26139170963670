import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import getProfilePhoto from "./getprofilePhoto";

const UserProfile = () => {
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [profilePhoto, setProfilePhoto] = useState<JSX.Element | null>(null);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const response = await instance.acquireTokenSilent({
          scopes: ["user.read"],
        });

        setAccessToken(response.accessToken);
      } catch (error) {
        console.error("Error acquiring access token:", error);
      }
    };

    getAccessToken();
  }, [instance, accounts]);

  useEffect(() => {
    const fetchProfilePhoto = async () => {
      if (accessToken) {
        const photo = await getProfilePhoto(accessToken);
        setProfilePhoto(photo);
      }
    };

    fetchProfilePhoto();
  }, [accessToken]);

  useEffect(() => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount?.name) {
      const initials = currentAccount.name
        .split(" ")
        .map((word) => word.charAt(0))
        .reverse()
        .join("")
        .slice(0, 2);
      setUsername(initials);
    }
  }, [instance]);

  return <div>{profilePhoto !== null ? profilePhoto : username}</div>;
};

export default UserProfile;
