import React, { FC, useEffect, useState } from "react";
import { UploadView } from "../viewers/UploadView";
import { useApi } from "../hooks/useApi";
import { useMsal } from "@azure/msal-react";

type Props = Record<string, never>;

const Home: FC<Props> = () => {
  const { getHealth } = useApi();
  const { accounts } = useMsal();
  const [isOnline, setIsOnline] = useState<boolean>(true);

  const getKID = () => {
    const account = accounts.at(0);
    if (account !== undefined) {
      return account.username.split("@")[0];
    }
    return "No KID found";
  };

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        getHealth()
          .then((response) => {
            if (response !== null && response.statusCode === 200) {
              setIsOnline(true);
              clearInterval(webPing);
            }
          })
          .catch(() => setIsOnline(false));
      }, 2000);
      return;
    }

    setIsOnline(false);
  };

  useEffect(() => {
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return function cleanup() {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  });

  return <UploadView isOnline={isOnline} getKID={getKID} />;
};

export default Home;
