import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Cancel, CheckCircle, CloudSync, Security } from "@mui/icons-material";
import { searchDateFormat, string2FullDate } from "../util/date";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import { useApi } from "../hooks/useApi";
import { paths, Process } from "../types";

// extend dayjs
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

type DateRange<T> = [T | null, T | null];
interface DatasetInfoProps {}

export enum PROCESS_PROCESSING_LEVEL {
  INITIALISED = "initialised",
  UPLOADED = "uploaded",
  PROCESSING = "processing",
  PROCESSED = "processed",
  PRIVACY_FILTERED = "privacy-filtered",
  CONSUMABLE = "consumable",
  FAILED = "failed",
}

const SidebarWidth = "400px";

const levelIcons: Record<PROCESS_PROCESSING_LEVEL, JSX.Element> = {
  [PROCESS_PROCESSING_LEVEL.PROCESSING]: <CloudSync />,
  [PROCESS_PROCESSING_LEVEL.PROCESSED]: <CloudSync />,
  [PROCESS_PROCESSING_LEVEL.FAILED]: <Cancel />,
  [PROCESS_PROCESSING_LEVEL.INITIALISED]: <CloudSync />,
  [PROCESS_PROCESSING_LEVEL.PRIVACY_FILTERED]: <Security />,
  [PROCESS_PROCESSING_LEVEL.UPLOADED]: <CloudSync />,
  [PROCESS_PROCESSING_LEVEL.CONSUMABLE]: <CheckCircle />,
};

export const DatasetInfo: FC<DatasetInfoProps> = () => {
  const { apiCall } = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [filteredDataset, setFilteredDataset] = useState<Process[]>([]);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs().subtract(1, "week"),
    dayjs(),
  ]);
  const [toggledError, setToggleError] = useState<string[]>([]);
  const [selectedProcessingLevel, setSelectedProcessingLevel] = useState<
    string[]
  >([]);

  const toggleProcessingLevel = (argType: string) => {
    setSelectedProcessingLevel((cuurTypes) =>
      cuurTypes.includes(argType) ? [] : [argType],
    );
  };

  const resetOptions = () => {
    setFilteredDataset([]);
    setToggleError([]);
  };

  useEffect(() => {
    let earliestDate = dayjs().subtract(1, "week");
    let latestDate = dayjs();

    if (dateRange[0] !== null) {
      earliestDate = dateRange[0];
    }
    if (dateRange[1] !== null) {
      latestDate = dateRange[1];
    }

    const filters = [
      {
        key: "startDate",
        value: earliestDate.format(searchDateFormat),
      },
      {
        key: "endDate",
        value: latestDate.format(searchDateFormat),
      },
    ];

    if (selectedProcessingLevel.length > 0) {
      filters.push({
        key: "processingLevel",
        value: selectedProcessingLevel[0],
      });
    }

    const filterString = filters.reduce((acc, val) => {
      return acc + `&${val.key}=${val.value}`;
    }, "");

    setLoading(true);
    resetOptions();

    apiCall<paths["/api/v1/processes"]>(`/processes?${filterString}`)
      .then(async (res) => {
        if (!res.results) {
          throw Error("Network Error.");
        }
        if (res.results?.length > 0) {
          res.results.sort((a, b) => {
            return dayjs(a.creationDate).isSameOrAfter(dayjs(b.creationDate))
              ? -1
              : 1;
          });
          setFilteredDataset(res.results);
        } else {
          // no process found
          setFilteredDataset([]);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setFilteredDataset([]);
      });
  }, [dateRange, selectedProcessingLevel]);

  /*useEffect(() => {
    setDatasets([...mockDataset, ...mockDataset.slice(1, 2), ...mockDataset]);
  }, []);

  const filteredDataset = useMemo(() => {
    const earliestDate =
      dateRange[0] !== null ? dateRange[0] : dayjs("1970-00-00");
    const latestDate = dateRange[1] !== null ? dateRange[1] : dayjs();
    const resultDataset = datasets
      .filter(
        (item) =>
          selectedProcessingLevel.length == 0 ||
          selectedProcessingLevel.includes(item.processingLevel),
      )
      .filter((item) =>
        dayjs(item.creationDate).isBetween(
          earliestDate,
          latestDate,
          "day",
          "[]",
        ),
      );
    resultDataset.sort((a, b) => {
      return dayjs(a.creationDate).isSameOrAfter(dayjs(b.creationDate))
        ? -1
        : 1;
    });
    return resultDataset;
  }, [datasets, selectedProcessingLevel, dateRange]);*/

  return (
    <Box height={1} display="flex" width={1}>
      <Box
        maxWidth={SidebarWidth}
        flexShrink={0}
        borderRight={1}
        height={1}
        borderColor="#dddddd"
        boxShadow="1px 0 9px 1px #c4c4c4"
      >
        <Box p={2}>
          <Typography variant="h1" fontSize={24}>
            Last Uploaded Dataset
          </Typography>
        </Box>
        <Divider sx={{ opacity: 0.1 }} variant="fullWidth" />
        <Box display="flex" flexDirection="column" px={2} mt={2} gap={2}>
          <Typography fontWeight={700}>Select Dates</Typography>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: "Check-in", end: "Check-out" }}
          >
            <DatePicker
              label="Start date"
              format="YYYY/MM/DD"
              value={dateRange[0]}
              onChange={(newValue) => {
                setDateRange((range) => [newValue, range[1]]);
              }}
            />
            <DatePicker
              label="End date"
              format="YYYY/MM/DD"
              value={dateRange[1]}
              onChange={(newValue) => {
                setDateRange((range) => [range[0], newValue]);
              }}
            />
          </LocalizationProvider>
        </Box>

        <Box px={2} mt={2} gap={2} display="flex" flexDirection="column">
          <Typography fontWeight={700}>Processing Level</Typography>
          <Box display="flex" gap={1} flexWrap="wrap">
            {Object.values(PROCESS_PROCESSING_LEVEL).map(
              (level: PROCESS_PROCESSING_LEVEL) => {
                return (
                  <Chip
                    icon={level in levelIcons ? levelIcons[level] : <></>}
                    key={level}
                    label={level}
                    onClick={() => toggleProcessingLevel(level)}
                    color={
                      selectedProcessingLevel.includes(level)
                        ? "error"
                        : "secondary"
                    }
                  />
                );
              },
            )}
          </Box>
        </Box>
      </Box>
      <Box width={1} display="flex" flexDirection="column">
        <Box p={2}>
          <Typography variant="h1" fontSize={24}>
            Results {!loading && <>({filteredDataset.length})</>}
          </Typography>
        </Box>
        <Divider sx={{ opacity: 0.1 }} variant="fullWidth" />
        {loading && (
          <Box
            display="flex"
            justifyContent="center"
            height={100}
            alignItems="center"
          >
            <CircularProgress size={24} />
          </Box>
        )}
        {!loading && filteredDataset.length === 0 && (
          <Typography color="error" p={2}>
            Selected criteria has no results.
          </Typography>
        )}
        <Grid
          container
          width={1}
          gap={2}
          p={2}
          gridTemplateColumns="repeat(3, minmax(0, 1fr))"
          display="grid"
          style={{
            overflowX: "scroll",
          }}
          alignItems="flex-start"
        >
          {filteredDataset.map((dataset, index) => {
            return (
              <Grid
                item
                key={index}
                p={1}
                width={1}
                px={2}
                border={1}
                borderColor="#dddddd"
                boxShadow="1px 0px 6px 0px #e9e9e9"
                borderRadius={1}
                height={1}
                sx={{
                  ":hover": {
                    borderColor: "#aaa",
                  },
                }}
                display="flex"
                flexDirection="column"
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    mb={1}
                    fontWeight={700}
                    style={{ wordBreak: "break-all" }}
                    mr={1}
                  >
                    {dataset.name}
                  </Typography>
                  <Box flexShrink={0}>
                    {[PROCESS_PROCESSING_LEVEL.CONSUMABLE].includes(
                      dataset.processingLevel as PROCESS_PROCESSING_LEVEL,
                    ) && <CheckCircle color="secondary" />}
                    {dataset.processingLevel ===
                      PROCESS_PROCESSING_LEVEL.FAILED && (
                      <Tooltip title="Show error" placement="top">
                        <Cancel
                          color="error"
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (dataset?.id) {
                              const datasetId = dataset.id;
                              setToggleError((val) =>
                                val.includes(datasetId)
                                  ? val.filter((id) => id !== datasetId)
                                  : val.concat(datasetId),
                              );
                            }
                          }}
                        />
                      </Tooltip>
                    )}
                    {[
                      PROCESS_PROCESSING_LEVEL.INITIALISED,
                      PROCESS_PROCESSING_LEVEL.UPLOADED,
                      PROCESS_PROCESSING_LEVEL.PROCESSED,
                      PROCESS_PROCESSING_LEVEL.PROCESSING,
                    ].includes(
                      dataset.processingLevel as PROCESS_PROCESSING_LEVEL,
                    ) && <CloudSync color="secondary" />}
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" height={1}>
                  <Typography variant="caption">
                    Created : {string2FullDate(dataset.creationDate)}
                  </Typography>
                  <Typography variant="caption" fontSize={14}>
                    Id : {dataset.id}
                  </Typography>
                  <Typography variant="caption" fontSize={14}>
                    Solution : {dataset?.relatedSolution?.name}
                  </Typography>
                  {toggledError.includes(dataset?.id ? dataset.id : "") &&
                    dataset?.errorMessages &&
                    dataset?.errorMessages.length > 0 &&
                    dataset.errorMessages.map((error) => (
                      <Box
                        display="flex"
                        height={1}
                        mt={1}
                        gap={1}
                        flexDirection="column"
                        key={error}
                      >
                        <Divider sx={{ opacity: 0.1 }} variant="fullWidth" />
                        <Typography
                          variant="caption"
                          fontSize={14}
                          color="error"
                          lineHeight="normal"
                        >
                          {error}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};
