import React, { FunctionComponent, useState } from "react";
import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

interface IconTooltipProps {
  text: string;
}

const IconTooltip: FunctionComponent<IconTooltipProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={props.text}
        >
          <IconButton onClick={handleTooltipOpen}>
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    </>
  );
};

export default IconTooltip;
