import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Home from "./screens/Home";
import { DatasetInfo } from "./screens/DatasetInfo";
import { eonTheme } from "@ipen-portal/portal-components";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import LoginButton from "./components/auth/LoginButton";
import Layout from "./components/Layout";
import { addAPIProvider } from "@iconify/react";
import { ICON_CDN } from "./util/images";

// icon CDN server
addAPIProvider("", {
  resources: [ICON_CDN],
});

const router = createBrowserRouter(
  [
    {
      element: <Layout />,
      children: [{ path: "/", Component: Home }],
    },
    {
      element: <Layout />,
      children: [{ path: "/dataset-overview", Component: DatasetInfo }],
    },
  ],
  {},
);

const AppContent = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <RouterProvider router={router} fallbackElement={<Home />} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginButton />
      </UnauthenticatedTemplate>
    </div>
  );
};

type Props = {
  msalInstance: PublicClientApplication;
};

function App({ msalInstance }: Props) {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      instance
        .ssoSilent({
          scopes: ["user.read"],
          loginHint: "",
        })
        .then((response) => {
          if (response && response.account) {
            instance.setActiveAccount(response.account);
          }
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .loginRedirect({
                scopes: ["user.read"],
              })
              .then((redirectResult) => {
                console.log("LoginRedirect success:", redirectResult);
              });
          }
        });
    }
  }, [isAuthenticated, instance]);

  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={eonTheme}>
        <AppContent />
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
